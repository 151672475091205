import AssessmentsView from "../views/AssessmentsView.vue";
import AssessmentQuestionsView from "../views/AssessmentQuestionsView.vue";

export const assessmentRoutes = [
  {
    path: "/assessments",
    name: "Assessments",
    component: AssessmentsView,
  },
  {
    path: "/assessments/:id/questions",
    name: "AssessmentQuestions",
    component: AssessmentQuestionsView,
  },
  {
    path: "/assessments/:id/question/:type", // should always be new, as edit requires question_id
    name: "AssessmentNewQuestion",
    component: () => import("../views/AssessmentNewQuestion.vue"),
  },
  {
    path: "/assessments/:id/question/:type/:question_id", // should always be 'edit' for type
    name: "AssessmenEditQuestion",
    component: () => import("../views/AssessmentNewQuestion.vue"),
  },
];
