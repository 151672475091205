import StudySitesView from '../views/StudySitesView.vue';
import VisitAssessmentsView from '../views/VisitAssessmentsView.vue';
import VisitsScheduleView from '../views/VisitsScheduleView.vue';
import StudiesView from '../views/StudiesView.vue';
import VisitsView from '../views/VisitsView.vue';
import SubjectsView from '../views/SubjectsView.vue';

export const studyRoutes = [
    {
        path: '/',
        name: 'Studies',
        component: StudiesView,
    },
    {
        path: '/studies/:id/sites',
        name: 'StudySites',
        component: StudySitesView,
    },
    {
        path: '/studies/:id/visits',
        name: 'Visits',
        component: VisitsView,
    },
    {
        path: '/studies/:id/subjects',
        name: 'Subjects',
        component: SubjectsView,
    },
    {
        path: '/studies/:id/visits/:visitId/assesments',
        name: 'VisitAssessments',
        component: VisitAssessmentsView,
    },
    {
        path: '/studies/:id/visits/schedule',
        name: 'VisitsSchedule',
        component: VisitsScheduleView,
    },
];