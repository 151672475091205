<template>
  <v-app :style="{ background: $vuetify.theme.currentTheme.background }">
    <idle
      @idle="onidle"
      @remind="onremind"
      @clearTimer="onactive"
      :loop="true"
      :events="['mousemove', 'keypress']"
      :reminders="[60]"
      :wait="25"
      :duration="idle_length - 25"
    />
    <v-theme-provider root v-if="showAppBar">
      <v-app-bar
        app
        :style="{ background: $vuetify.theme.currentTheme.surface }"
      >
        <v-app-bar-title class="text-no-wrap">
          <div>
            <branding></branding>
          </div>
        </v-app-bar-title>

        <v-spacer></v-spacer>
        <v-tabs right>
          <v-tab to="/"> Studies </v-tab>
          <v-tab to="/sites" v-if="$store.state.user.role != 'clinician'">
            Sites
          </v-tab>
          <v-tab to="/assessments" v-if="$store.state.user.role != 'clinician'">
            Assessments
          </v-tab>
          <v-tab to="/users" v-if="$store.state.user.role != 'clinician'">
            Users
          </v-tab>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                Data
                <v-icon right> mdi-menu-down </v-icon>
              </v-tab>
            </template>

            <v-list class="grey lighten-3">
              <v-list-item to="/datatable"> CRF Data </v-list-item>
              <v-list-item
                to="/audit_study"
                v-if="$store.state.user.role != 'clinician'"
              >
                Audit - Study
              </v-list-item>
              <v-list-item
                to="/audit_global"
                v-if="$store.state.user.role != 'clinician'"
              >
                Audit - Global
              </v-list-item>
              <v-list-item to="/data/compliance_reporting">
                Compliance Reporting
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tab @click="logOut">
            Logout <v-icon class="ml-1">mdi-exit-to-app</v-icon>
          </v-tab>
        </v-tabs>
      </v-app-bar>
    </v-theme-provider>

    <v-theme-provider root>
      <v-main>
        <router-view />
        <footer class="version">Version: {{ VERSION }}</footer>

        <v-snackbar
          v-model="$store.state.snack.show"
          :timeout="3000"
          :color="$store.state.snack.color"
        >
          {{ $store.state.snack.text }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="$store.state.snack.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-theme-provider>
  </v-app>
</template>

<style scoped>
.version {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5em;
  font-size: xx-small;
  color: darkgray;
}
</style>

<script>
import Branding from "@/components/Branding.vue";
import hasuraService from "@/services/hasura.service.js";
import idle from "@/components/Idle.vue";
import { VERSION } from "@/version.js";

export default {
  name: "App",
  mixins: [hasuraService],
  components: {
    branding: Branding,
    idle,
  },

  data: () => ({
    VERSION,
    idle_length: 1800, // in seconds
  }),

  mounted() {
    // check on idle, on page load
    if (localStorage.getItem("token")) {
      let last_active = localStorage.getItem("last_active");
      if (last_active) {
        let diff = Date.now() - last_active;
        if (diff > 1000 * this.idle_length) {
          this.onidle();
        } else {
          console.log("User was last active " + diff / 1000 + " seconds ago");
          this.onactive();
        }
      }
    }

    /// Preload assessments
    this.$store.state.jwt_loading = new Promise((resolve, reject) => {
      this.$store.state.jwt_resolve = resolve;
      this.$store.state.jwt_reject = reject;
    });
    const $this = this;
    let t = localStorage.getItem("token");
    if (t) {
      let decoded = this.parseJwt(t);

      // the role is just short hand so i dont have to do this everywhere

      this.$store.commit("setUser", {
        ...decoded,
        role: decoded["https://hasura.io/jwt/claims"]["x-hasura-role"],
        id: +decoded.sub,
      });

      this.$store.state.jwt_resolve();
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    showAppBar() {
      return !this.$route.meta.hideAppbar;
    },
  },
  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    logOut() {
      localStorage.removeItem("token");
      window.location = "/login";
    },
    onactive() {
      localStorage.setItem("last_active", Date.now());
    },
    onidle() {
      localStorage.setItem("next_page", window.location.pathname);
      alert("You have been logged out");
      localStorage.removeItem("token");
      window.location = "/login";
    },
    onremind(time) {
      alert(time + " seconds until you are logged out for being idle.");
    },
  },
};
</script>
