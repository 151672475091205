import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apolloProvider from "./plugins/apollo";
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://8547d31ac7c20dceebc88349bb59290a@o4504616849244160.ingest.us.sentry.io/4507188617543680",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // "http://localhost:8080/",
      "https://comsdccaptureadmindev.z5.web.core.windows.net",
      "https://comsdccaptureadminstagin.z5.web.core.windows.net",
      "https://comsdccaptureadminuat.z5.web.core.windows.net",
      "https://comsdccaptureadmin.z5.web.core.windows.net",
      "https://capture.sdcclinical.com",
    ],


    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
