<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ formTitle }}
            <span v-if="formTitle == 'Edit User'">| ID: {{ userId }}</span>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="userEmail"
                  :error-messages="emailErrorMessage"
                  @keyup="removeErrorMessages"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Name"
                  required
                  v-model="userName"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  disabled
                  label="User Type"
                  required
                  v-model="userType"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="formTitle == 'Edit User'">
                <v-select
                  label="Change Reason"
                  required
                  :items="$store.state.change_reason_options"
                  v-model="change_reason"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateOrCreateUser"
            :loading="isLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "user"],
  mixins: [hasuraService],
  data: () => ({
    isLoading: false,
    formTitle: "New User",

    old_user: null,
    is_edit: false,

    userId: null,
    userEmail: "",
    userName: "",
    userType: "clinician",
    userTypeSelectOptions: [{ text: "Clinician", value: "clinician" }],
    emailErrorMessage: "",
    environment: "uat",
    change_reason: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newVal) {
        if (!(newVal === null || newVal === undefined)) {
          this.formTitle = "Edit User";
          this.userId = newVal.id;
          this.userEmail = newVal.email;
          this.userName = newVal.name;
          this.userType = newVal.type;
          this.general_application_audit_log_data(
            this.userId,
            "dia_app_user"
          ).then((r) => {
            this.old_user = r;
          });

          this.is_edit = true;
        }
      },
    },
  },
  methods: {
    removeErrorMessages() {
      console.log("removeErrorMessages");
      this.emailErrorMessage = "";
    },
    async updateOrCreateUser() {
      this.isLoading = true;

      // validate email
      if (!this.userEmail) {
        this.emailErrorMessage = "Email is required";
        this.isLoading = false;
        return;
      }
      // validate email as being a valid email
      if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.userEmail)) {
        this.emailErrorMessage = "Email is not valid";
        this.isLoading = false;
        return;
      }

      if (!this.userName) {
        alert("Please enter a name for the user.");
        this.isLoading = false;
        return;
      }
      
      if (this.is_edit) {
        if (this.old_user.email !== this.userEmail) {
          // check if email already exists
          const is_unique = await this.checkEmailUnique(this.userEmail);
          if (!is_unique) {
            this.emailErrorMessage = "Email already exists";
            this.isLoading = false;
            return;
          }
        }
      }

      if (this.is_edit) {
        // check change reason
        if (!this.change_reason) {
          this.$store.commit("setSnack", {
            text: "Please select a change reason",
            color: "error",
          });
          this.isLoading = false;
          return;
        }

        if (this.old_user.email !== this.userEmail) {
          // check if email already exists
          const is_unique = await this.checkEmailUnique(this.userEmail);
          if (!is_unique) {
            this.emailErrorMessage = "Email already exists";
            this.isLoading = false;
            return;
          }
        }
      }

      try {
        let userData = null;
        if (this.userId) {
          userData = await this.updateUserRequest(
            this.userId,
            this.userEmail,
            this.userName,
            this.userType
          );
        } else {
          const result = await this.createUserRequest(
            this.userEmail,
            this.userName,
            this.userType
          );
          if (result.result) {
            // fetch user from hasura
            userData = await this.getUserByEmailRequest(this.userEmail);
          } else {
            this.emailErrorMessage = result.reason;
            return;
          }
        }
        this.general_application_audit_log(
          this.$store.state.user,
          "dia_app_user",
          userData.id,
          this.old_user,
          null, // study_id
          this.change_reason
        );

        if (userData) {
          this.$emit("callback", userData);
          this.show = false;
        }
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        this.$store.commit("setSnack", {
          text: "An error occurred. Please try again.",
          color: "error",
        });
      }
    },
  },
  mounted() {
    this.environment = localStorage.getItem("environment") || "uat";
  },
};
</script>
