import gql from "graphql-tag";
import randomString from "../utils/random-string";
import aesjs from "aes-js";

export default {
  data() {
    return {
      audit_log_meta_table_data: {
        dia_app_user: [
          "archived",
          "force_password_reset",
          "email",
          "login",
          "name",
          "type",
          "id",
          "password_attempts",
          "pin_attempts",
          "expires",
          "password_last_updated",
          "pin_last_updated",
          "archived_at",
          "created_at",
          "updated_at",
        ],
        dia_app_subject: [
          "archived",
          "email",
          "login",
          "subject_full_id",
          "created_by",
          "device_id",
          "id",
          "site_id",
          "study_id",
          "token",
          "archived_at",
          "created_at",
        ],
      },
    };
  },
  methods: {
    _pad(password) {
      // password must be a multiple of 16 bytes
      // using 0x00 to pad
      var textBytes = aesjs.utils.utf8.toBytes(password);
      var padding = 16 - (textBytes.length % 16);
      var paddedBytes = new Array(textBytes.length + padding);
      for (var i = 0; i < textBytes.length; i++) {
        paddedBytes[i] = textBytes[i];
      }
      for (var i = textBytes.length; i < paddedBytes.length; i++) {
        paddedBytes[i] = 0x00;
      }
      return paddedBytes;
    },
    _encrypt_password(password) {
      var key = [
        227, 178, 0, 240, 2, 106, 172, 89, 39, 82, 74, 58, 79, 10, 109, 81, 104,
        182, 234, 59, 213, 101, 153, 188, 216, 58, 234, 31, 171, 210, 20, 72,
      ];
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var text = this._pad(password);
      var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
      var encryptedBytes = aesCbc.encrypt(text);
      return btoa(String.fromCharCode.apply(null, encryptedBytes));
    },

    async general_application_audit_log_data(id, table, force_network = false) {
      let fields = this.audit_log_meta_table_data[table];

      if (!fields) {
        console.warn("Table not found in audit log meta data");
        return;
      }

      let query = `query GetAuditLogData($id: Int!) {
        ${table}(where: {id: {_eq: $id}}) {
          ${fields.join("\n")}
        }
      }`;

      const { data } = await this.$apollo.query({
        query: gql(query),
        fetchPolicy: force_network ? "network-only" : "cache-first",
        variables: {
          id,
        },
      });

      console.log("general_application_audit_log_data", data);

      return data[`${table}`][0];
    },
    async general_application_audit_log(
      user,
      table,
      id,
      old_data,
      study,
      change_reason = ""
    ) {
      // if table isn't in this.audit_log_meta_table_data, return
      if (!this.audit_log_meta_table_data[table]) {
        console.warn("Table not found in audit log meta data");
        return;
      }
      if (old_data == null) {
        old_data = {};
      }
      let pretty_table = table.replace("dia_app_", "");
      let is_new = false;
      let action_taken = "";
      if (change_reason == "") {
        action_taken = "create_" + pretty_table;
        is_new = true;
      } else {
        action_taken = "update_" + pretty_table;
      }

      let new_data = await this.general_application_audit_log_data(
        id,
        table,
        true
      );

      new_data = JSON.parse(JSON.stringify(new_data));
      old_data = JSON.parse(JSON.stringify(old_data));
      delete new_data.__typename;
      delete old_data.__typename;

      // we need to get a list of changes, by iterating over the old_data and new data
      // and comparing the values, if they are different, we add the field to the changes list
      let fields = [];
      if (!is_new) {
        for (let field in old_data) {
          if (old_data[field] != new_data[field]) {
            fields.push(field);
          }
        }
      } else {
        fields = Object.keys(new_data);
      }

      // convert fields to a comma separated string for the query
      let user_identifier = user.id + ": " + user.email;
      let item = table + "";
      // action_taken
      let fields_str = fields.join(", ");
      let old_value = JSON.stringify(old_data);
      let new_value = JSON.stringify(new_data);
      let record_id = id + "";

      let query = gql`
        mutation insert_dia_app_application_audit_log_one(
          $action_taken: String!
          $field_name: String!
          $item: String!
          $new_value: String!
          $old_value: String!
          $record_id: String!
          $user_identifier: String!
          $study_id: Int = null
          $study_name: String = null
          $change_reason: String = ""
        ) {
          insert_dia_app_application_audit_log_one(
            object: {
              action_taken: $action_taken
              field_name: $field_name
              item: $item
              new_value: $new_value
              old_value: $old_value
              record_id: $record_id
              user_identifier: $user_identifier
              study_id: $study_id
              study_name: $study_name
              change_reason: $change_reason
            }
          ) {
            id
          }
        }
      `;

      let variables = {
        action_taken,
        field_name: fields_str,
        item: pretty_table,
        new_value,
        old_value,
        record_id,
        study_id: study?.id || null,
        study_name: study?.name || null,
        user_identifier,
        change_reason,
      };

      const { data } = await this.$apollo.mutate({
        mutation: query,
        variables,
      });

      return data.insert_dia_app_application_audit_log_one;
    },
    async login(email, password) {
      const query = gql`
        query LoginSite(
          $auth_key: String!
          $login: String!
          $login_type: String!
          $auth_type: String!
        ) {
          action_login(
            login: $login
            login_type: $login_type
            auth_type: $auth_type
            auth_key: $auth_key
          ) {
            jwt
            reason
            result
            first_login
            userType
          }
        }
      `;
      const variables = {
        login: email,
        login_type: "password", // Not being used ??
        auth_type: "password",
        auth_key: this._encrypt_password(password),
      };

      const uatResult = await this.$apollo.query({
        query,
        variables,
      });

      if (uatResult.data && uatResult.data.action_login.result) {
        localStorage.setItem("token", uatResult.data.action_login.jwt);
        return {
          success: true,
          reason: "",
        };
      }
      return {
        success: uatResult.data.action_login.result,
        reason: uatResult.data.action_login.reason,
      };
    },
    async listSubjects() {
      const { data } = await this.$apollo.query({
        query: gql`
          query ListSubjects {
            dia_app_subject {
              id
              study_id
              subject_full_id
              updated_at
              created_by
              created_at
              archived
            }
          }
        `,
      });
      return data.dia_app_subject;
    },
    async checkSubjectEmailUnique(email) {
      const { data } = await this.$apollo.query({
        query: gql`
          query SubjectEmailUnqiue($email: String!) {
            dia_app_subject_aggregate(where: { email: { _eq: $email } }) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: {
          email,
        },
      });

      return data.dia_app_subject_aggregate.aggregate.count === 0;
    },
    async updateSubject(subjectData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateSubject(
            $id: Int!
            $site_id: Int!
            $email: String!
            $subject_study_site_id: Int!
            $subject_full_id: String!
          ) {
            update_dia_app_subject(
              where: { id: { _eq: $id } }
              _set: { email: $email }
            ) {
              returning {
                id
              }
            }
            update_dia_app_subject_study_site(
              where: { id: { _eq: $subject_study_site_id } }
              _set: { subject_full_id: $subject_full_id, site_id: $site_id }
            ) {
              returning {
                id
              }
            }
          }
        `,
        variables: subjectData,
      });

      if (
        data &&
        data.update_dia_app_subject &&
        data.update_dia_app_subject.returning
      ) {
        return data.update_dia_app_subject.returning[0];
      }

      return false;
    },

    // tracking

    async trackPageView(apolloProvider, email, url) {
      try {
        const { data } = await apolloProvider.defaultClient.mutate({
          mutation: gql`
            mutation TrackUsage($email: String!, $url: String!) {
              action_usage_log(email: $email, url: $url) {
                message
                result
                reason
              }
            }
          `,
          variables: {
            email,
            url,
          },
        });

        if (data && data.action_usage_log && data.action_usage_log.result) {
          return true;
        } else {
          console.log("trackPageView failed");
          return false;
        }
      } catch (error) {
        console.error(error);
        console.log("trackPageView failed");
        return false;
      }
    },

    async getIntervalAssessmentsForWindow(study_id) {
      let query = `
      query GetIntervalAssessmentsForWindow($study_id: Int! = 3) {
        dia_app_interval_assessment(where: {study_id: {_eq: $study_id}}, order_by: {sequence: asc}) {
          id
          sequence
          interval {
            name
            id
          }
          assessment {
            name
            id
          }
        }
      }      
      `;

      const { data } = await this.$apollo.query({
        query: gql(query),
        variables: {
          study_id,
        },
      });

      return data.dia_app_interval_assessment;
    },

    // Studies
    async listStudies(userId, admin = false) {
      let result = null;
      if (admin) {
        result = await this._listStudiesAdmin();
      } else {
        result = await this._listStudiesUser(userId);
      }

      // deep copy because we are going to modify the data
      let data = JSON.parse(JSON.stringify(result));

      // subject_study_site_aggregate should take precedence over subjects, for new studies
      data = data.map((study) => {
        if (study.subject_study_sites_aggregate.aggregate.count != 0) {
          study.subjects_aggregate.aggregate.count =
            study.subject_study_sites_aggregate.aggregate.count;
        }
        return study;
      });

      return data;
    },

    async _listStudiesAdmin() {
      let query = `query ListStudies {
        dia_app_study(order_by: {id: asc}) {
          archived
          locked
          locked_at
          id
          name
          description
          edc
          external_id
          indication
          managing_cro
          phase
          sponsor
          theraputic_area
          created_at
          archived
          intervals_aggregate(where: {archived: {_eq: false}}) {
            aggregate {
              count
            }
          }
          subject_study_sites_aggregate(where: {archived: {_eq: false}}) {
            aggregate {
              count
            }
          }
          subjects_aggregate(where: {archived: {_eq: false}}) {
            aggregate {
              count
            }
          }
          intervals_aggregate_archived:intervals_aggregate(where: {archived: {_eq: true}}) {
            aggregate {
              count
            }
          }
          study_sites(where: {study: {archived: {_eq: false}}}) {
            study_id
            site_id
            site_name
            site_identifier
            site {
              id
              name
              address
              city
              state
              zip
              created_at
              updated_at
            }
          }
        }
      }`;

      const { data } = await this.$apollo.query({
        query: gql(query),
      });

      return data.dia_app_study;
    },
    async _listStudiesUser(userId) {
      let query = `query ListStudies($userId: Int!) {
        dia_app_user_study(distinct_on: [study_id], where: {user_id: {_eq: $userId}}, order_by: {study_id: asc}) {
          study {
            id
            locked
            locked_at
            name
            description
            edc
            external_id
            indication
            managing_cro
            phase
            sponsor
            theraputic_area
            created_at
            archived
            intervals_aggregate(where: {archived: {_eq: false}}) {
              aggregate {
                count
              }
            }
            intervals_aggregate_archived:intervals_aggregate(where: {archived: {_eq: true}}) {
              aggregate {
                count
              }
            }
            subject_study_sites_aggregate(where: {archived: {_eq: false}, site: {user_study_sites: {user_id: {_eq: $userId}}}}) {
              aggregate {
                count
              }
            }
            subjects_aggregate(where: {archived: {_eq: false}}) {
              aggregate {
                count
              }
            }
            study_sites(where: {study: {archived: {_eq: false}}}) {
              study_id
              site_id
              site_name
              site_identifier
              site {
                id
                name
                address
                city
                state
                zip
                created_at
                updated_at
              }
            }
          }
        }
      }`;

      let v = {
        userId: userId,
      };

      const { data } = await this.$apollo.query({
        query: gql(query),
        variables: v,
      });
      // the conversion is to keep the data struct consistent with previous version
      return data.dia_app_user_study.map((study) => study.study);
    },
    async subjectFormSiteList(study_id, user_id, archived = false) {
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchStudySite(
            $study_id: Int!
            $user_id: Int!
            $archived: Boolean!
          ) {
            dia_app_user_study_site(
              where: {
                study_id: { _eq: $study_id }
                archived: { _eq: $archived }
                user_id: { _eq: $user_id }
              }
              distinct_on: site_id
            ) {
              site {
                id
                name
              }
            }
            dia_app_study(where: { id: { _eq: $study_id } }) {
              id
              name
              subject_id_regex_validation
              subject_id_regex_help_text
            }
          }
        `,
        variables: {
          study_id,
          user_id,
          archived,
        },
      });

      return {
        study: data.dia_app_study[0],
        sites: data.dia_app_user_study_site.map((site) => site.site),
      };
    },
    async fetchStudySiteData(study_id, archived, force_network = false) {
      // fetch all study sites for a study
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchStudySite($study_id: Int!, $archived: Boolean!) {
            dia_app_study_site(
              where: {
                study_id: { _eq: $study_id }
                archived: { _eq: $archived }
              }
            ) {
              study_id
              site_id
              site_name
              site_identifier
              investigator_email
              archived
              study {
                id
                name
                subject_id_regex_validation
                subject_id_regex_help_text
              }
              site {
                id
                name
                address
                city
                state
                zip
                created_at
                updated_at
              }
            }
          }
        `,
        fetchPolicy: force_network ? "network-only" : "cache-first",
        variables: {
          study_id,
          archived,
        },
      });

      return data.dia_app_study_site;
    },
    async fetchStudySiteDataLimited(
      study_id,
      archived,
      user_id,
      force_network = false
    ) {
      // fetch all study sites for a study
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchStudySite($study_id: Int!, $archived: Boolean!) {
            dia_app_study_site(
              where: {
                study_id: { _eq: $study_id }
                archived: { _eq: $archived }
              }
            ) {
              study_id
              site_id
              site_name
              site_identifier
              investigator_email
              archived
              site {
                id
                name
                address
                city
                state
                zip
                created_at
                updated_at
              }
            }
          }
        `,
        fetchPolicy: force_network ? "network-only" : "cache-first",
        variables: {
          study_id,
          archived,
        },
      });

      return data.dia_app_study_site;
    },
    async createSubject(subjectData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation AddUser(
            $created_by: String!
            $email: String!
            $login: String!
            $password: String!
            $current_date: String!
            $site_id: String!
            $study_id: String!
            $subject_full_id: String!
            $pin: String!
          ) {
            action_subject_register(
              created_by: $created_by
              study_id: $study_id
              subject_full_id: $subject_full_id
              email: $email
              login: $login
              password: $password
              current_date: $current_date
              site_id: $site_id
              pin: $pin
            ) {
              message
              reason
              result
            }
          }
        `,
        variables: subjectData,
      });

      console.log("createSubject", data);
      return data.action_subject_register.message; // message is the subject_full_id
    },
    async fetchStudyData(id, archived = false) {
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchStudy($id: Int!, $archived: Boolean!) {
            dia_app_study(where: { id: { _eq: $id } }) {
              id
              name
              description
              locked
              edc
              external_id
              indication
              managing_cro
              phase
              sponsor
              medical_monitor_email
              subject_id_regex_validation
              subject_id_regex_help_text
              theraputic_area
              created_at
              intervals_aggregate {
                aggregate {
                  count
                }
              }
              study_sites(where: { study: { archived: { _eq: $archived } } }) {
                study_id
                site_id
                site_name
                site_identifier
                archived
                site {
                  id
                  name
                  address
                  city
                  state
                  zip
                  created_at
                  updated_at
                }
              }
            }
          }
        `,
        variables: {
          id,
          archived,
        },
      });

      if (data.dia_app_study.length === 1) {
        return data.dia_app_study[0];
      }

      return false;
    },
    async createStudy(studyData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateStudy(
            $name: String!
            $description: String!
            $edc: String!
            $externalId: String!
            $sponsor: String!
            $medical_monitor_email: String
            $subject_id_regex_validation: String
            $subject_id_regex_help_text: String
          ) {
            insert_dia_app_study(
              objects: {
                description: $description
                edc: $edc
                external_id: $externalId
                name: $name
                sponsor: $sponsor
                medical_monitor_email: $medical_monitor_email
                subject_id_regex_validation: $subject_id_regex_validation
                subject_id_regex_help_text: $subject_id_regex_help_text
              }
            ) {
              returning {
                id
                name
                description
                edc
                external_id
                indication
                managing_cro
                phase
                sponsor
                medical_monitor_email
                subject_id_regex_validation
                subject_id_regex_help_text
                theraputic_area
                created_at
                intervals_aggregate {
                  aggregate {
                    count
                  }
                }
                study_sites(where: { study: { archived: { _eq: false } } }) {
                  study_id
                  site_id
                  site_name
                  site_identifier
                  site {
                    id
                    name
                    address
                    city
                    state
                    zip
                    created_at
                    updated_at
                  }
                }
              }
            }
          }
        `,
        variables: studyData,
      });

      if (
        data &&
        data.insert_dia_app_study &&
        data.insert_dia_app_study.returning
      ) {
        return data.insert_dia_app_study.returning[0];
      }

      return false;
    },
    async lock_unlock_study(study_id, locked) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation LockUnlockStudy($id: Int!, $locked: Boolean!) {
            update_dia_app_study(
              where: { id: { _eq: $id } }
              _set: { locked: $locked, locked_at: "now()" }
            ) {
              returning {
                id
                locked
              }
            }
          }
        `,
        variables: {
          id: study_id,
          locked,
        },
      });

      if (
        data &&
        data.update_dia_app_study &&
        data.update_dia_app_study.returning
      ) {
        return data.update_dia_app_study.returning[0];
      }

      return false;
    },
    async updateStudyById(studyData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateStudy(
            $id: Int!
            $name: String!
            $description: String!
            $edc: String!
            $externalId: String!
            $sponsor: String!
            $medical_monitor_email: String
            $subject_id_regex_validation: String
            $subject_id_regex_help_text: String
          ) {
            update_dia_app_study(
              where: { id: { _eq: $id } }
              _set: {
                sponsor: $sponsor
                name: $name
                external_id: $externalId
                edc: $edc
                description: $description
                medical_monitor_email: $medical_monitor_email
                subject_id_regex_validation: $subject_id_regex_validation
                subject_id_regex_help_text: $subject_id_regex_help_text
              }
            ) {
              returning {
                id
                name
                description
                subject_id_regex_validation
                subject_id_regex_help_text
                edc
                external_id
                indication
                managing_cro
                phase
                sponsor
                theraputic_area
                created_at
                intervals_aggregate {
                  aggregate {
                    count
                  }
                }
                study_sites(where: { study: { archived: { _eq: false } } }) {
                  study_id
                  site_id
                  site_name
                  site_identifier
                  site {
                    id
                    name
                    address
                    city
                    state
                    zip
                    created_at
                    updated_at
                  }
                }
              }
            }
          }
        `,
        variables: studyData,
      });

      if (
        data &&
        data.update_dia_app_study &&
        data.update_dia_app_study.returning
      ) {
        return data.update_dia_app_study.returning[0];
      }

      return false;
    },
    async archiveStudyById(studyId, archived = true) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveStudy($id: Int!, $archived: Boolean!) {
            update_dia_app_study(
              where: { id: { _eq: $id } }
              _set: { archived: $archived }
            ) {
              returning {
                id
                archived
              }
            }
          }
        `,
        variables: {
          id: studyId,
          archived,
        },
      });

      if (
        data &&
        data.update_dia_app_study &&
        data.update_dia_app_study.returning
      ) {
        return data.update_dia_app_study.returning[0];
      }

      return false;
    },
    async attachSiteToStudy(
      studyId,
      siteId,
      siteName,
      siteIdentifier,
      investigatorEmail
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation AttachSiteToStudy(
            $siteId: Int!
            $siteIdentifier: String!
            $siteName: String!
            $studyId: Int!
            $investigatorEmail: String
          ) {
            insert_dia_app_study_site(
              objects: {
                site_id: $siteId
                study_id: $studyId
                site_identifier: $siteIdentifier
                site_name: $siteName
                investigator_email: $investigatorEmail
              }
              on_conflict: {
                constraint: study_site_pkey
                update_columns: [site_name, site_identifier, investigator_email]
                where: {
                  site_id: { _eq: $siteId }
                  study_id: { _eq: $studyId }
                }
              }
            ) {
              returning {
                study_id
                site_id
                site_name
                site_identifier
                investigator_email
                archived
                site {
                  id
                  name
                  identifier
                  address
                  city
                  state
                  zip
                  created_at
                  updated_at
                }
              }
            }
          }
        `,
        variables: {
          studyId,
          siteId,
          siteName,
          siteIdentifier,
          investigatorEmail,
        },
      });

      console.log("attachSiteToStudy", data);

      if (
        data &&
        data.insert_dia_app_study_site &&
        data.insert_dia_app_study_site.returning
      ) {
        console.log("made it here");
        return data.insert_dia_app_study_site.returning[0];
      }

      return false;
    },
    async archiveSiteFromStudy(siteId, studyId, archived = true) {
      console.log("archiveSiteFromStudy", siteId, studyId, archived);
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveSiteFromStudy(
            $siteId: Int!
            $studyId: Int!
            $archived: Boolean!
          ) {
            update_dia_app_study_site(
              where: { site_id: { _eq: $siteId }, study_id: { _eq: $studyId } }
              _set: { archived: $archived }
            ) {
              returning {
                study_id
                site_id
                archived
              }
            }
          }
        `,
        variables: {
          studyId,
          siteId,
          archived,
        },
      });

      if (
        data &&
        data.update_dia_app_study_site &&
        data.update_dia_app_study_site.returning
      ) {
        console.log("made it here");
        console.log(data.update_dia_app_study_site.returning);
        return true;
      }

      return false;
    },
    // Visits
    async listVisits(id, archived = false, cached = true) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ListVisits($id: Int!, $archived: Boolean!) {
            dia_app_interval(
              order_by: { sequence: asc }
              where: { archived: { _eq: $archived }, study_id: { _eq: $id } }
            ) {
              id
              name
              positive_slack_window
              negative_slack_window
              alert_start_time
              alert_start_text
              time_delay
              time_unit
              sequence
              study_id
              archived
              interval_assessments(where: { archived: { _eq: false } }) {
                assessment_id
                positive_slack_window
                negative_slack_window
                time_delay
                time_unit
                study_id
                sequence
                interval_id
                archived
                assessment {
                  name
                }
              }
            }
          }
        `,
        fetchPolicy: cached ? "cache-first" : "network-only",
        variables: {
          id,
          archived,
        },
      });
      return data.dia_app_interval;
    },
    async fetchVisitData(id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchVisit($id: Int!) {
            dia_app_interval(where: { id: { _eq: $id } }) {
              id
              name
              positive_slack_window
              negative_slack_window
              time_delay
              time_unit
              unscheduled
              alert_start_time
              alert_start_text
              sequence
              study_id
              interval_assessments(where: { archived: { _eq: false } }) {
                assessment_id
                positive_slack_window
                negative_slack_window
                time_delay
                time_unit
                study_id
                sequence
                interval_id
                archived
                assessment {
                  name
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (data.dia_app_interval.length === 1) {
        return data.dia_app_interval[0];
      }

      return false;
    },
    async createStudyVisit(visitData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateStudyVisit(
            $name: String!
            $negative_slack_window: Int
            $positive_slack_window: Int
            $sequence: Int!
            $study_id: Int!
            $time_delay: Int
            $time_unit: String!
            $unscheduled: Boolean
            $alert_start_time: alert_start_time
            $alert_start_text: alert_start_text
          ) {
            insert_dia_app_interval(
              objects: {
                name: $name
                negative_slack_window: $negative_slack_window
                positive_slack_window: $positive_slack_window
                sequence: $sequence
                study_id: $study_id
                time_delay: $time_delay
                time_unit: $time_unit
                unscheduled: $unscheduled
                alert_start_time: $alert_start_time
                alert_start_text: $alert_start_text
              }
            ) {
              returning {
                id
                name
                positive_slack_window
                negative_slack_window
                study_id
                time_delay
                time_unit
                unscheduled
                alert_start_time
                alert_start_text
                sequence
                interval_assessments(where: { archived: { _eq: false } }) {
                  id
                  assessment_id
                  positive_slack_window
                  negative_slack_window
                  time_delay
                  time_unit
                  study_id
                  sequence
                  interval_id
                  assessment {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: visitData,
      });

      if (
        data &&
        data.insert_dia_app_interval &&
        data.insert_dia_app_interval.returning
      ) {
        return data.insert_dia_app_interval.returning[0];
      }

      return false;
    },
    async updateStudyVisit(visitData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateStudyVisit(
            $id: Int!
            $name: String!
            $negative_slack_window: Int
            $positive_slack_window: Int
            $sequence: Int!
            $study_id: Int!
            $time_delay: Int
            $time_unit: String!
            $unscheduled: Boolean
            $alert_start_time: time
            $alert_start_text: String
          ) {
            update_dia_app_interval(
              where: { id: { _eq: $id } }
              _set: {
                name: $name
                negative_slack_window: $negative_slack_window
                positive_slack_window: $positive_slack_window
                sequence: $sequence
                study_id: $study_id
                time_delay: $time_delay
                time_unit: $time_unit
                unscheduled: $unscheduled
                alert_start_time: $alert_start_time
                alert_start_text: $alert_start_text
              }
            ) {
              returning {
                id
                name
                positive_slack_window
                negative_slack_window
                study_id
                time_delay
                time_unit
                unscheduled
                alert_start_time
                alert_start_text
                sequence
                interval_assessments(where: { archived: { _eq: false } }) {
                  id
                  assessment_id
                  positive_slack_window
                  negative_slack_window
                  time_delay
                  time_unit
                  study_id
                  sequence
                  interval_id
                  assessment {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: visitData,
      });

      if (
        data &&
        data.update_dia_app_interval &&
        data.update_dia_app_interval.returning
      ) {
        return data.update_dia_app_interval.returning[0];
      }

      return false;
    },
    async archiveStudyVisitById(visitId, archive) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveStudyVisit($id: Int!, $archive: Boolean!) {
            update_dia_app_interval(
              where: { id: { _eq: $id } }
              _set: { archived: $archive }
            ) {
              returning {
                id
                archived
              }
            }
          }
        `,
        variables: {
          id: visitId,
          archive,
        },
      });

      if (
        data &&
        data.update_dia_app_study &&
        data.update_dia_app_study.returning
      ) {
        return data.update_dia_app_study.returning[0];
      }

      return false;
    },
    async updateStudyVisitSequense(visitData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateStudyVisitSequenses($id: Int!, $sequence: Int!) {
            update_dia_app_interval(
              where: { id: { _eq: $id } }
              _set: { sequence: $sequence }
            ) {
              returning {
                id
                sequence
              }
            }
          }
        `,
        variables: visitData,
      });

      if (
        data &&
        data.update_dia_app_interval &&
        data.update_dia_app_interval.returning
      ) {
        return data.update_dia_app_interval.returning[0];
      }

      return false;
    },
    // Assessments
    async listAssessments(archived = false, cached = true) {
      const { data } = await this.$apollo.query({
        fetchPolicy: cached ? "cache-first" : "network-only",
        query: gql`
          query ListAssessments($archived: Boolean!) {
            dia_app_assessment(where: { archived: { _eq: $archived } }) {
              interval_assessments_aggregate {
                aggregate {
                  count(distinct: true, columns: study_id)
                }
              }
              id
              name
              description
              created_at
              validated
              archived
              version_number
              questions(where: { archived: { _eq: false } }) {
                id
                assessment_id
                label
                help_text
                question_type
                required
                sequence
                time_delay
                variable_name
                options(where: { archived: { _eq: false } }) {
                  id
                  label
                  min_value
                  max_value
                  data_type
                  sequence
                  step
                  value
                }
              }
            }
          }
        `,
        variables: {
          archived,
        },
      });
      return data.dia_app_assessment;
    },
    async listAssessmentsByVisitsIds(ids, archived = false, cached = true) {
      // TODO: Why is this a list of ids?
      // The only caller uses a single id
      const { data } = await this.$apollo.query({
        query: gql`
          query ListAssessments($ids: [Int!]!, $archived: Boolean!) {
            dia_app_interval_assessment(
              where: {
                interval_id: { _in: $ids }
                archived: { _eq: $archived }
              }
              order_by: { sequence: asc }
            ) {
              id
              assessment_id
              interval_id
              positive_slack_window
              negative_slack_window
              sequence
              required
              study_id
              time_delay
              time_unit
              cycle
              archived
              clinician_reported
              allow_out_of_window_completion
              schedule_start_assessment
              business_logic
              interval_assessment_anchor_for_slack_window
              assessment {
                id
                name
                description
              }
            }
          }
        `,
        fetchPolicy: cached ? "cache-first" : "network-only",
        variables: { ids, archived },
      });
      return data.dia_app_interval_assessment;
    },
    async listAssessmentsByStudyId(studyId) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ListAssessmentsByStudyId($studyId: Int!) {
            dia_app_assessment(
              where: { interval_assessments: { study_id: { _eq: $studyId } } }
            ) {
              id
              name
              interval_assessments {
                interval_id
                interval {
                  name
                }
              }
            }
          }
        `,
        variables: { studyId },
      });
      return data.dia_app_assessment;
    },
    async fetchAssessmentData(id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query FetchAssessment($id: Int!) {
            dia_app_assessment(where: { id: { _eq: $id } }) {
              id
              name
              created_at
              description
              validated
              archived
              version_number
              questions(where: { archived: { _eq: false } }) {
                id
                assessment_id
                label
                help_text
                question_type
                required
                sequence
                time_delay
                variable_name
                options(where: { archived: { _eq: false } }) {
                  id
                  label
                  min_value
                  max_value
                  data_type
                  sequence
                  step
                  value
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (data.dia_app_assessment.length === 1) {
        return data.dia_app_assessment[0];
      }

      return false;
    },
    async createAssessment(assessmentData) {
      // name: this.assessment.name,
      // description: this.assessment.description,
      // version_number: this.assessment.version_number,
      // validated: this.assessment.validated,
      // archived: this.assessment.archived,

      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateAssessment(
            $name: String!
            $description: String!
            $version_number: String!
            $validated: Boolean!
            $archived: Boolean!
          ) {
            insert_dia_app_assessment(
              objects: {
                name: $name
                description: $description
                version_number: $version_number
                validated: $validated
                archived: $archived
              }
            ) {
              returning {
                id
                name
                description
              }
            }
          }
        `,
        variables: assessmentData,
      });

      if (
        data &&
        data.insert_dia_app_assessment &&
        data.insert_dia_app_assessment.returning
      ) {
        return data.insert_dia_app_assessment.returning[0];
      }

      return false;
    },
    async addVisitAssessmentRequest(requestData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation AddAssessment(
            $set: dia_app_interval_assessment_insert_input!
          ) {
            insert_dia_app_interval_assessment(objects: [$set]) {
              returning {
                id
                assessment_id
                interval_id
                positive_slack_window
                negative_slack_window
                required
                sequence
                business_logic
                time_delay
                time_unit
                clinician_reported
                allow_out_of_window_completion
                schedule_start_assessment
                interval_assessment_anchor_for_slack_window
                assessment {
                  id
                  name
                  description
                }
              }
            }
          }
        `,
        variables: {
          set: requestData,
        },
      });

      if (
        data &&
        data.insert_dia_app_interval_assessment &&
        data.insert_dia_app_interval_assessment.returning
      ) {
        return data.insert_dia_app_interval_assessment.returning[0];
      }

      return false;
    },
    async archiveVisitAssessmentById(id, archived = true) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveVisitAssessment($id: Int!, $archived: Boolean!) {
            update_dia_app_interval_assessment(
              _set: { archived: $archived }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                archived
              }
            }
          }
        `,
        variables: {
          id,
          archived,
        },
      });

      if (
        data &&
        data.update_dia_app_interval_assessment &&
        data.update_dia_app_interval_assessment.returning
      ) {
        return data.update_dia_app_interval_assessment.returning[0];
      }

      return false;
    },
    async updateVisitAssessmentById(id, requestData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateVisitAssessment(
            $id: Int!
            $set: dia_app_interval_assessment_set_input!
          ) {
            update_dia_app_interval_assessment(
              _set: $set
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                assessment_id
                interval_id
                positive_slack_window
                negative_slack_window
                required
                sequence
                business_logic
                time_delay
                time_unit
                clinician_reported
                allow_out_of_window_completion
                schedule_start_assessment
                interval_assessment_anchor_for_slack_window
              }
            }
          }
        `,
        variables: {
          id,
          set: requestData,
        },
      });

      if (
        data &&
        data.update_dia_app_interval_assessment &&
        data.update_dia_app_interval_assessment.returning
      ) {
        return data.update_dia_app_interval_assessment.returning[0];
      }

      return false;
    },
    async updateStudyVisitAssessmentSequense(visitAssessmentData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateVisitAssessmentSequence($id: Int!, $sequence: Int!) {
            update_dia_app_interval_assessment(
              _set: { sequence: $sequence }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                sequence
              }
            }
          }
        `,
        variables: visitAssessmentData,
      });

      if (
        data &&
        data.update_dia_app_interval_assessment &&
        data.update_dia_app_interval_assessment.returning
      ) {
        return data.update_dia_app_interval_assessment.returning[0];
      }

      return false;
    },
    async updateAssessmentById(assessmentData) {
      console.log("updateAssessmentById", assessmentData);
      let id = assessmentData.id;
      delete assessmentData.id;

      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateAssessment(
            $id: Int!
            $name: String
            $description: String
            $validated: Boolean
            $archived: Boolean
            $version_number: String
          ) {
            update_dia_app_assessment(
              where: { id: { _eq: $id } }
              _set: {
                name: $name
                description: $description
                validated: $validated
                archived: $archived
                version_number: $version_number
              }
            ) {
              returning {
                id
              }
            }
          }
        `,
        variables: {
          id,
          ...assessmentData,
        },
      });
    },
    // Assessment Questions
    async listQuestionsbyAssessmentId(id, cached = true) {
      // when we delete a question, we need to make sure we are not using the cached version
      const { data } = await this.$apollo.query({
        fetchPolicy: cached ? "cache-first" : "network-only",
        query: gql`
          query AssessmentQuestions($id: Int!) {
            dia_app_question(
              where: { assessment_id: { _eq: $id } }
              order_by: { sequence: asc }
            ) {
              archived
              created_at
              id
              label
              question_type
              sequence
              updated_at
              options_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      });
      return data.dia_app_question;
    },
    async getQuestionById(id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query dia_app_question_by_pk($id: Int!) {
            dia_app_question_by_pk(id: $id) {
              archived
              help_text
              help_text_es
              label
              label_es
              required
              question_type
              sequence
              time_delay
              variable_name
              options(order_by: { sequence: asc }) {
                id
                archived
                attributes
                created_at
                data_type
                label
                max_value
                max_video_duration
                media_countdown
                media_countdown_prompt
                media_countdown_prompt_es
                media_flash_mode
                media_length
                media_zoom
                number_of_decimals
                min_label_text
                max_label_text
                min_value
                min_video_duration
                sequence
                step
                updated_at
                value
              }
            }
          }
        `,
        variables: {
          id,
        },
      });

      return data.dia_app_question_by_pk;
    },

    async genericArchive(id, archive, tableName) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation Archive($id: Int!, $archive: Boolean!) {
            update_${tableName}(
              where: { id: { _eq: $id } }
              _set: { archived: $archive, archived_at: "now()"}
            ) {
              returning {
                id
              }
            }
          }
        `,
        variables: {
          id,
          archive,
        },
      });

      return data[`update_${tableName}`].returning[0];
    },
    async archiveQuestionById(id, archive) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveQuestion($id: Int!, $archive: Boolean!) {
            update_dia_app_question(
              where: { id: { _eq: $id } }
              _set: { archived: $archive }
            ) {
              returning {
                id
                archived
              }
            }
          }
        `,
        variables: {
          id,
          archive,
        },
      });

      if (
        data &&
        data.delete_dia_app_question &&
        data.delete_dia_app_question.returning
      ) {
        return data.delete_dia_app_question.returning[0];
      }

      return false;
    },

    async saveQuestion(questionData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_dia_app_question_one(
            $object: dia_app_question_insert_input!
          ) {
            insert_dia_app_question_one(object: $object) {
              id
            }
          }
        `,
        variables: {
          object: questionData,
        },
      });

      if (
        data &&
        data.insert_dia_app_question_one &&
        data.insert_dia_app_question_one.id
      ) {
        return data.insert_dia_app_question_one.id;
      }

      return false;
    },
    async updateQuestion(questionData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation MyMutation(
            $id: Int = 10
            $archived: Boolean = false
            $help_text: String = ""
            $help_text_es: String = ""
            $label: String = ""
            $label_es: String = ""
            $question_type: String = ""
            $required: Boolean = false
            $sequence: Int = 10
            $time_delay: Int = 10
            $variable_name: String = ""
          ) {
            update_dia_app_question_by_pk(
              pk_columns: { id: $id }
              _set: {
                archived: $archived
                help_text: $help_text
                help_text_es: $help_text_es
                label: $label
                label_es: $label_es
                question_type: $question_type
                required: $required
                sequence: $sequence
                time_delay: $time_delay
                variable_name: $variable_name
              }
            ) {
              id
            }
          }
        `,
        variables: questionData,
      });

      if (
        data &&
        data.update_dia_app_question_by_pk &&
        data.update_dia_app_question_by_pk.id
      ) {
        return data.update_dia_app_question_by_pk.id;
      }

      return false;
    },
    async saveQuestionOptions(optionsData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_dia_app_option_one(
            $object: dia_app_option_insert_input!
          ) {
            insert_dia_app_option_one(object: $object) {
              id
            }
          }
        `,
        variables: {
          object: optionsData,
        },
      });

      if (
        data &&
        data.insert_dia_app_question_one &&
        data.insert_dia_app_question_one.id
      ) {
        return data.insert_dia_app_question_one.id;
      }

      return false;
    },
    async updateQuestionOption(id, optionsData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_dia_app_option_by_pk(
            $id: Int!
            $_set: dia_app_option_set_input!
          ) {
            update_dia_app_option_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id,
          _set: optionsData,
        },
      });

      if (
        data &&
        data.update_dia_app_option_by_pk &&
        data.update_dia_app_option_by_pk.id
      ) {
        return data.update_dia_app_option_by_pk.id;
      }

      return false;
    },
    async deleteQuestionOptionById(id) {
      console.log("Deleting option with id: ", id);
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation delete_dia_app_option_by_pk($id: Int!) {
            delete_dia_app_option_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (
        data &&
        data.delete_dia_app_option_by_pk &&
        data.delete_dia_app_option_by_pk.id
      ) {
        return true;
      }
      return false;
    },
    // Sites
    async listSitesRequest(archived = false, force_network = true) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ListSites($archived: Boolean!) {
            dia_app_site(
              order_by: { id: desc }
              where: { archived: { _eq: $archived } }
            ) {
              id
              name
              address
              city
              state
              zip
              created_at
              updated_at
              archived
            }
          }
        `,
        fetchPolicy: force_network ? "network-only" : "cache-first",
        variables: {
          archived,
        },
      });
      return data.dia_app_site;
    },
    async createSiteRequest(siteData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateSite(
            $name: String!
            $address: String!
            $city: String!
            $state: String!
            $zip: String!
          ) {
            insert_dia_app_site(
              objects: {
                name: $name
                address: $address
                city: $city
                state: $state
                zip: $zip
              }
            ) {
              returning {
                id
                name
                address
                city
                state
                zip
                created_at
                updated_at
              }
            }
          }
        `,
        variables: siteData,
      });

      if (
        data &&
        data.insert_dia_app_site &&
        data.insert_dia_app_site.returning
      ) {
        return data.insert_dia_app_site.returning[0];
      }

      return false;
    },
    async updateSiteRequest(siteData) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateSite(
            $id: Int!
            $name: String!
            $address: String!
            $city: String!
            $state: String!
            $zip: String!
          ) {
            update_dia_app_site(
              _set: {
                name: $name
                address: $address
                city: $city
                state: $state
                zip: $zip
              }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                name
                address
                city
                state
                zip
                created_at
                updated_at
              }
            }
          }
        `,
        variables: siteData,
      });

      if (
        data &&
        data.update_dia_app_site &&
        data.update_dia_app_site.returning
      ) {
        return data.update_dia_app_site.returning[0];
      }

      return false;
    },

    async archiveSiteRequest(siteId, archive = true) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveSite($id: Int!, $archive: Boolean!) {
            update_dia_app_site(
              _set: { archived: $archive }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                archived
              }
            }
          }
        `,
        variables: {
          id: siteId,
          archive,
        },
      });

      if (
        data &&
        data.update_dia_app_site &&
        data.update_dia_app_site.returning
      ) {
        return data.update_dia_app_site.returning[0];
      }

      return false;
    },

    // Users
    async checkEmailUnique(email) {
      email = email.toLowerCase();

      const { data } = await this.$apollo.query({
        query: gql`
          query EmailUniqueness($email: String!) {
            dia_app_user(where: { email: { _eq: $email } }) {
              id
            }
          }
        `,
        variables: {
          email,
        },
      });

      return data.dia_app_user.length === 0;
    },
    async listUsers() {
      const { data } = await this.$apollo.query({
        query: gql`
          query ListUsers {
            dia_app_user(where: { archived: { _eq: false } }) {
              id
              name
              email
              type
              created_at
              updated_at
              user_studies(where: { archived: { _eq: false } }) {
                study_id
                user_id
                study {
                  id
                  name
                  description
                  user_study_sites {
                    site_id
                  }
                }
              }
            }
          }
        `,
      });
      return data.dia_app_user;
    },
    async getUserByIdRequest(id, cached = true) {
      const { data } = await this.$apollo.query({
        query: gql`
          query GetUserById($id: Int!) {
            dia_app_user(where: { id: { _eq: $id } }) {
              id
              name
              email
              type
              created_at
              updated_at
              user_studies(where: { archived: { _eq: false } }) {
                study_id
                user_id
                study {
                  id
                  name
                  description
                  sponsor
                  user_study_sites(where: { user_id: { _eq: $id } }) {
                    site_id
                  }
                }
              }
            }
          }
        `,
        fetchPolicy: cached ? "cache-first" : "network-only",
        variables: {
          id,
        },
      });

      if (data && data.dia_app_user && data.dia_app_user.length) {
        return data.dia_app_user[0];
      }

      return false;
    },
    async getUserByEmailRequest(email) {
      const { data } = await this.$apollo.query({
        query: gql`
          query GetUserByEmail($email: String!) {
            dia_app_user(where: { email: { _eq: $email } }) {
              id
              name
              email
              type
              created_at
              updated_at
              user_studies(where: { archived: { _eq: false } }) {
                study_id
                user_id
                study {
                  id
                  name
                  description
                  sponsor
                  user_study_sites {
                    site_id
                  }
                }
              }
            }
          }
        `,
        variables: {
          email,
        },
      });

      if (data && data.dia_app_user && data.dia_app_user.length) {
        return data.dia_app_user[0];
      }

      return false;
    },
    async archiveUserByIdRequest(userId) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ArchiveUser($id: Int!) {
            update_dia_app_user(
              _set: { archived: true }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
              }
            }
          }
        `,
        variables: {
          id: userId,
        },
      });

      if (
        data &&
        data.update_dia_app_user &&
        data.update_dia_app_user.returning
      ) {
        return data.update_dia_app_user.returning[0];
      }

      return false;
    },
    async attachStudyToUser(userId, studyId) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation AttachUserStudy($userId: Int!, $studyId: Int!) {
            insert_dia_app_user_study(
              objects: { user_id: $userId, study_id: $studyId }
            ) {
              returning {
                study_id
                user_id
                study {
                  id
                  name
                  description
                  sponsor
                  user_study_sites(where: { user_id: { _eq: $userId } }) {
                    site_id
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId,
          studyId,
        },
      });

      if (
        data &&
        data.insert_dia_app_user_study &&
        data.insert_dia_app_user_study.returning
      ) {
        return data.insert_dia_app_user_study.returning[0];
      }

      return false;
    },
    async replaceUserStudySites(userId, studyId, sitesIds) {
      const insertData = sitesIds.map((siteId) => ({
        user_id: userId,
        study_id: studyId,
        site_id: siteId,
      }));

      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ReplaceUserStudySites(
            $userId: Int!
            $studyId: Int!
            $insertData: [dia_app_user_study_site_insert_input]!
          ) {
            delete_dia_app_user_study_site(
              where: { user_id: { _eq: $userId }, study_id: { _eq: $studyId } }
            ) {
              returning {
                site_id
                study_id
              }
            }
            insert_dia_app_user_study_site(objects: $insertData) {
              returning {
                site_id
              }
            }
          }
        `,
        variables: {
          userId,
          studyId,
          insertData,
        },
      });

      if (
        data &&
        data.insert_dia_app_user_study_site &&
        data.insert_dia_app_user_study_site.returning
      ) {
        return {
          userStudySites: data.insert_dia_app_user_study_site.returning,
          studyId,
        };
      }

      return false;
    },
    async createUserRequest(email, name, type) {
      const mutation = gql`
        mutation RegisterUser(
          $login: String!
          $email: String!
          $password: String!
          $name: String!
          $user_type: String!
        ) {
          action_user_register(
            login: $login
            email: $email
            password: $password
            name: $name
            user_type: $user_type
          ) {
            result
            reason
          }
        }
      `;
      const variables = {
        login: email,
        email,
        password: randomString(10, "aA#!"), // password is mandatory in hasura action
        name,
        user_type: type,
      };
      const result = await this.$apollo.mutate({
        mutation,
        variables,
      });

      if (result.data && result.data.action_user_register.result) {
        return {
          result: true,
          reason: `${result.data.action_user_register.reason}.`,
        };
      }
      return {
        result: false,
        reason: `${result.data.action_user_register.reason}.`,
      };
    },
    async updateUserRequest(userId, email, name, type) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUser(
            $id: Int!
            $name: String!
            $email: String!
            $type: String!
          ) {
            update_dia_app_user(
              _set: { name: $name, email: $email, type: $type, login: $email }
              where: { id: { _eq: $id } }
            ) {
              returning {
                id
                name
                email
                type
                created_at
                updated_at
                user_studies(where: { archived: { _eq: false } }) {
                  study_id
                  user_id
                  study {
                    id
                    name
                    description
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: userId,
          name,
          email,
          type,
        },
      });

      if (data && data.update_dia_app_user.result) {
        return data.update_dia_app_user.result[0];
      } else if (data && data.update_dia_app_user.returning) {
        return data.update_dia_app_user.returning[0];
      }
      return false;
    },
    async passwordResetStepOne(email) {
      const query = gql`
        query PasswordResetStepOne($email: String!, $user_type: String!) {
          action_password_reset_step_1(email: $email, user_type: $user_type) {
            result
            reason
          }
        }
      `;
      const variables = {
        email,
        user_type: "admin",
      };

      const result = await this.$apollo.query({
        query,
        variables,
      });

      if (result.data) {
        return result.data.action_password_reset_step_1;
      }
      return { errors: [{ message: "API error. Please contact support." }] };
    },
    // async passwordResetStepTwo(password, userType, token) {
    //   const body = {
    //     input: {
    //       password,
    //       user_type: userType,
    //       token,
    //     },
    //   };

    //   const headers = {
    //     'Content-Type': 'application/json',
    //   };

    //   const result = await fetch(
    //     'https://com-sdcclinical-eyecup-uat.azurewebsites.net/api/password_reset_step_two',
    //     {
    //       method: 'POST',
    //       headers,
    //       body: JSON.stringify(body),
    //     },
    //   );

    //   // if status is 200, return the result
    //   if (result.status === 200) {
    //     // eslint-disable-next-line no-return-await
    //     return await result.json();
    //   }
    //   return { errors: [{ message: 'API error. Please contact support.' }] };
    // },

    async passwordResetStepTwo(password, userType, token) {
      const query = gql`
        query PasswordResetStepTwo(
          $password: String!
          $user_type: String!
          $token: String!
        ) {
          action_password_reset_step_2(
            password: $password
            user_type: $user_type
            token: $token
          ) {
            result
            reason
          }
        }
      `;

      const variables = {
        password,
        user_type: userType,
        token,
      };

      // const result = await this.$apollo.query({
      //   query,
      //   variables,
      // });
      // console.log(result)
      var result = null;
      try {
        result = await this.$apollo.query({
          query,
          variables,
        });
        console.log("RESULT", result);
      } catch (e) {
        console.log("error", e);
      }

      if (result?.data) {
        return result.data.action_password_reset_step_2;
      }
      return { errors: [{ message: "API error" }] };
    },
  },
};
