import DataTableView from "../views/DataTableView.vue";

export const dataRoutes = [
  {
    path: "/datatable",
    name: "DataTable",
    component: DataTableView,
  },
  {
    path: "/audit_study",
    name: "AuditStudyLog",
    component: () => import("../views/AuditLogStudyView.vue"),
  },
  {
    path: "/audit_global",
    name: "AuditGlobalLog",
    component: () => import("../views/AuditLogGlobalView.vue"),
  },
  {
    path: "/data/compliance_reporting",
    name: "ComplianceReporting",
    component: () => import("../views/Data/ComplianceReporting.vue"),
  },
];
