<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">Sites</h1>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Sites"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>
          <v-btn
            color="primary"
            @click="newSite"
            :disabled="$store.state.app_locked"
          >
            New Site
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.address="{ item }">
        {{
          item.address?.length > 50
            ? item.address.substring(0, 50) + "..."
            : item.address
        }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-2"
          icon
          small
          @click="editSite(item)"
          :disabled="$store.state.app_locked"
        >
          <v-icon dark> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="archiveSite(item)"
          :disabled="$store.state.app_locked"
        >
          <v-icon v-if="!toggle_archive" dark> mdi-delete-outline </v-icon>
          <v-icon v-else dark> mdi-delete-restore </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <site-form
      v-model="showSiteFormDialog"
      v-bind:site="siteToEdit"
      @callback="siteFormCallback"
    ></site-form>
    <archive-site-dialog
      v-model="showArchiveSiteDialog"
      v-bind:site="siteToArchive"
      v-bind:archive="toggle_archive"
      v-if="showArchiveSiteDialog"
      @callback="archiveSiteCallback"
    ></archive-site-dialog>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import SiteForm from "@/components/sites/SiteForm.vue";
import ArchiveSiteDialog from "@/components/sites/ArchiveSiteDialog.vue";

export default {
  name: "SitesView",
  mixins: [hasuraService],
  components: {
    "site-form": SiteForm,
    "archive-site-dialog": ArchiveSiteDialog,
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Zip", value: "zip" },
        { text: "Actions", value: "actions", width: "120px", sortable: false },
      ],
      data: [],
      showSiteFormDialog: false,
      showArchiveSiteDialog: false,
      siteToArchive: null,
      siteToEdit: null,
    };
  },

  watch: {
    toggle_archive() {
      this.listSites(this.toggle_archive);
    },
  },

  methods: {
    async newSite() {
      this.siteToEdit = null;
      this.showSiteFormDialog = true;
    },
    async siteFormCallback(siteData) {
      const siteIndex = this.data.findIndex((site) => site.id === siteData.id);
      if (siteIndex > -1) {
        this.data[siteIndex].name = siteData.name;
        this.data[siteIndex].address = siteData.address;
        this.data[siteIndex].city = siteData.city;
        this.data[siteIndex].state = siteData.state;
        this.data[siteIndex].zip = siteData.zip;
      } else {
        this.data.unshift(siteData);
      }
    },
    async archiveSite(site) {
      this.siteToArchive = site;
      this.showArchiveSiteDialog = true;
    },
    async editSite(site) {
      this.siteToEdit = site;
      this.showSiteFormDialog = true;
    },

    async archiveSiteCallback(siteId) {
      this.data = this.data.filter((site) => site.id !== siteId);
    },
    async listSites(archive) {
      // lets not use manual caching until
      // we have a better understanding of the
      // use case and the data

      // const storedSites = this.$store.getters.sites;
      // if (Object.keys(storedSites).length > 0) {
      //   this.data = Object.values(storedSites);
      // } else {
      //   this.$store.commit("mergeSites", { sites: this.data, reload: true });
      // }
      this.loading = true;
      this.data = [];
      this.data = JSON.parse(
        JSON.stringify(await this.listSitesRequest(archive))
      );
      this.loading = false;
    },
  },
  async mounted() {
    this.listSites(this.toggle_archive);
  },
};
</script>
