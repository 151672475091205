<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field label="Name" required v-model="visitName"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Time delay" required type="number" v-model="visitTimeDelay"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="visitTimeUnit" :items="visitTimeUnitSelectOptions" label="Time unit"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Positive slack window" required type="number"
                  v-model="visitPosSlackWindow"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Negative slack window" required type="number"
                  v-model="visitNegSlackWindow"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="visitUnscheduled" label="Unscheduled?"></v-checkbox>
              </v-col>
              <v-col cols="6">
              </v-col>

              <v-col cols="6">
                <v-row class="m-0">
                  <v-col cols="5" style="padding-top:0; padding-bottom: 0;">
                    Alert Time
                  </v-col>
                </v-row>
                <v-row style="margin-top:0">
                  <v-col cols="5">
                    <!-- dropdown with hours -->
                    <v-select v-model="alert_start_time__hours" :items="alert_start_time__hours_options"
                      label="Hours"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <!-- dropdown with minutes -->
                    <v-select v-model="alert_start_time__minutes" :items="alert_start_time__minutes_options"
                      label="Minutes"></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Alert Message" required v-model="alert_start_text"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="visitUnscheduled"
                  label="Unscheduled?"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="cancel"> Cancel </v-btn>
          <v-btn color="primary" @click="updateOrCreateVisit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "StudyVisitForm",
  props: ["value", "studyId", "visitId"],
  mixins: [hasuraService],
  data: () => ({
    formTitle: "New Visit",

    alert_start_time: "",
    alert_start_text: "",

    alert_start_time__hours: null,
    alert_start_time__minutes: null,
    alert_start_time__hours_options: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    alert_start_time__minutes_options: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
    visitName: "",
    visitSequence: 1000,
    visitTimeDelay: null,
    visitTimeUnit: null,
    visitPosSlackWindow: null,
    visitNegSlackWindow: null,
    visitUnscheduled: false,
    visitTimeUnitSelectOptions: [
      {
        text: "Please Select",
        value: null,
      },
      {
        text: "Minutes",
        value: "minutes",
      },
      {
        text: "Hours",
        value: "hours",
      },
      {
        text: "Days",
        value: "days",
      },
    ],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async value(newVal) {
      if (this.visitId !== null) {
        this.formTitle = "Edit Visit";
        this.visit = await this.fetchVisitData(this.visitId);

        this.visitName = this.visit.name;
        this.visitSequence = this.visit.sequence;
        this.visitTimeDelay = this.visit.time_delay;
        this.visitTimeUnit = this.visit.time_unit;
        this.visitPosSlackWindow = this.visit.positive_slack_window;
        this.visitNegSlackWindow = this.visit.negative_slack_window;
        this.visitUnscheduled = this.visit.unscheduled;
        this.alert_start_text = this.visit.alert_start_text;

        if (this.visit.alert_start_time !== null) {
          const alert_start_time = this.visit.alert_start_time.split(":");
          this.alert_start_time__hours = alert_start_time[0];
          this.alert_start_time__minutes = alert_start_time[1];
        }

      } else {
        this.formTitle = "New Visit";
        this.visitName = "";
        this.visitSequence = 1000;
        this.visitTimeDelay = null;
        this.visitTimeUnit = null;
        this.visitPosSlackWindow = null;
        this.visitNegSlackWindow = null;
        this.visitUnscheduled = false;
        this.alert_start_time__hours = null;
        this.alert_start_time__minutes = null;
        this.alert_start_text = "";

      }
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit("cancel");
    },
    async updateOrCreateVisit() {
      if (this.visitName === "") {
        alert("Please enter a name for the visit.");
        return;
      }
      let alert_start_time = null;
      if (this.alert_start_time__hours !== null && this.alert_start_time__minutes !== null) {
        alert_start_time = this.alert_start_time__hours + ":" + this.alert_start_time__minutes + ":00";
      }
      const visitData = {
        name: this.visitName,
        sequence: this.visitSequence,
        time_delay: this.visitTimeDelay,
        time_unit: this.visitTimeUnit,
        positive_slack_window: this.visitPosSlackWindow,
        negative_slack_window: this.visitNegSlackWindow,
        unscheduled: this.visitUnscheduled,
        study_id: this.studyId,
        alert_start_time: alert_start_time,
        alert_start_text: this.alert_start_text,
      };

      let responseData = null;
      if (this.visitId !== null) {
        visitData.id = this.visitId;
        responseData = await this.updateStudyVisit(visitData);
      } else {
        responseData = await this.createStudyVisit(visitData);
      }

      if (responseData !== false) {
        this.$store.commit("mergeVisits", [responseData]);
      }
      this.$emit("callback", responseData);

      // reset form
      this.visitName = "";
      this.visitSequence = 1000;
      this.visitTimeDelay = null;
      this.visitTimeUnit = null;
      this.visitPosSlackWindow = null;
      this.visitNegSlackWindow = null;
      this.visitUnscheduled = false;
      this.alert_start_time__hours = null;
      this.alert_start_time__minutes = null;
      this.alert_start_text = "";


      this.show = false;
    },
  },
};
</script>
